import React from 'react';


const Header = () => {


    return (
        <div className='header-body'>
            <p className='header-body__sub-text'> Exxon Suarez</p>
            <h1 className='header-body__main-text'>Web Developer </h1>
        </div>
    )
}


export default Header;