import React from 'react';
import ReactDOM from 'react-dom';
import './styles/styles.scss'
import App from './App';
import reportWebVitals from './reportWebVitals';




ReactDOM.render( <App />,document.getElementById('root'));

reportWebVitals();
